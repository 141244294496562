import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  .pagination-wrapper {
    height: fit-content;
    width: fit-content;
    button {
      width: auto;
      height: auto;
      background-color: transparent;
      padding: 0 6px;
      margin: 0 4px;
      max-height: 19px;
      &.active,
      :hover {
        background-color: #981214;
      }
      &.prev-btn,
      &.next-btn {
        &:hover {
          background-color: transparent;
          color: #981214;
        }
      }
    }
    .blank-page {
      padding: 0 6px;
      margin: 0 4px;
      max-height: 19px;
      line-height: 1;
      color: white;
    }
  }

  @media only screen and (max-width: ${device.sizeS}) {
    .pagination-wrapper {
      > * {
        font-size: 12px;
      }
      button {
        margin: 0 2px;
        padding: 0 4px;
      }
      .blank-page {
        line-height: normal;
      }
    }
  }
`
