import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/dynamic'

import { withTranslation } from '../../../i18n'

import PaginationStyled from './styled'

export class Pagination extends React.PureComponent {
  handlePageOnClicked = page => {
    this.props.onPageClicked(page)
  }

  handlePrevPageClicked = () => {
    if (this.props.currentPage > 1) {
      this.props.onPageClicked(this.props.currentPage - 1)
    }
  }

  handleNextPageClicked = () => {
    if (this.props.currentPage < this.props.totalPages) {
      this.props.onPageClicked(this.props.currentPage + 1)
    }
  }

  prepareListPage() {
    const { currentPage, pageNeighbours, totalPages } = this.props
    const listPage = [...Array(this.props.totalPages).keys()].map(x => x + 1)

    const startRange = currentPage - (pageNeighbours + 1) <= 0 ? 1 : currentPage - (pageNeighbours + 1)
    const endRange = currentPage + pageNeighbours > totalPages - 1 ? totalPages - 1 : currentPage + pageNeighbours
    const rangeValues = listPage.slice(startRange, endRange)

    const beginDot = startRange - 1 <= 0 ? null : '...'
    const lastDot = endRange + 1 >= totalPages ? null : '...'
    const lastPage = totalPages > 1 ? totalPages : null

    const preparedList = [1, beginDot, ...rangeValues, lastDot, lastPage].filter(data => !!data)
    return preparedList
  }

  getEachPageButton() {
    const listPages = this.prepareListPage()
    const output = listPages.map((number, index) => {
      let btn = (
        <div key={`blank-page-${index}`} id={`blank-page-${index}`} className="blank-page">
          ...
        </div>
      )
      if (number !== '...') {
        let pageClassName = 'page-btn'
        pageClassName += number === this.props.currentPage ? ' active' : ''
        btn = (
          <Button
            id={`btn-${number}`}
            key={`btn-${number}`}
            className={pageClassName}
            text={number}
            data={number}
            onClick={this.handlePageOnClicked}
          />
        )
      }
      return btn
    })
    return output
  }

  getPagination() {
    const isFirstPage = this.props.currentPage <= 1
    const isLastPage = this.props.currentPage >= this.props.totalPages
    const prevBtn = (
      <Button id="prev-btn" disabled={isFirstPage} className="prev-btn" text={`< ${this.props.t('previous')}`} onClick={this.handlePrevPageClicked} />
    )
    const nextBtn = (
      <Button id="next-btn" disabled={isLastPage} className="next-btn" text={`${this.props.t('next')} >`} onClick={this.handleNextPageClicked} />
    )
    return (
      <div className="pagination-wrapper flex">
        {prevBtn}
        {this.getEachPageButton()}
        {nextBtn}
      </div>
    )
  }

  render() {
    return <PaginationStyled>{this.getPagination()}</PaginationStyled>
  }
}

Pagination.defaultProps = {
  currentPage: 1,
  totalPages: 10,
  pageNeighbours: 1,
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageClicked: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(Pagination)
